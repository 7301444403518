<template>
  <v-card>
    <v-data-table
      v-model="thisSelected"
      :headers="headers"
      :items="documents"
      disable-filtering
      disable-pagination
      hide-default-footer
      checkbox-color="msaBlue"
      item-key="id"
      :show-select="$route.name == 'CompanyDocumentLibrary'"
      @item-selected="onSelect($event)"
      @toggle-select-all="onSelect($event)"
      class="private-document-list"
    >
      <template v-slot:[`header.data-table-select`] v-if="disableSelectAll">
        <v-simple-checkbox disabled></v-simple-checkbox>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <FileTypeIcon :filename="item.originalFilename" />
        <a v-if="!item.isDeleted" @click="downloadDocument(item)">
          {{ item.name }}
        </a>
        <span v-if="item.isDeleted" class="text-decoration-line-through">
          {{ item.name }}
        </span>
        <span v-if="item.isExpired" class="red--text text-caption font-italic">
          (Expired)
        </span>
        <span v-if="item.isDeleted" class="red--text text-caption font-italic">
          (Deleted)
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.expiryDate`]="{ item }">
        {{ item.expiryDate | filterAsLocalDate }}
      </template>
      <template
        v-slot:[`item.actions`]="{ item }"
        v-if="$route.name == 'CompanyDocumentLibrary'"
      >
        <v-tooltip bottom v-if="!item.isDeleted">
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              icon
              color="msaBlue"
              v-on="on"
              @click="$emit('showDocumentDetails', item.id)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!item.isDeleted">
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              icon
              v-on="on"
              @click="$emit('showAssignments', item)"
            >
              <v-icon color="msaBlue"> mdi-account </v-icon>
            </v-btn>
          </template>
          <span>Assignments</span>
        </v-tooltip>
        <PublishToggle
          v-if="!item.isDeleted"
          :is-published="item.isPublished"
          @click="togglePublish(item)"
        />
        <v-tooltip bottom v-if="!item.isDeleted">
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              icon
              v-on="on"
              @click="$emit('showLabels', item.id)"
              :disabled="!item.isPublished"
            >
              <v-icon color="msaBlue"> mdi-tag </v-icon>
            </v-btn>
          </template>
          <span>Groups</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-blur icon v-on="on" @click="toggleDeletion(item)">
              <v-icon color="red" v-if="!item.isDeleted"> mdi-delete </v-icon>
              <v-icon color="msaBlue" v-if="item.isDeleted">
                mdi-delete-restore
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.isDeleted ? 'Restore' : 'Delete' }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import FileTypeIcon from '@/components/FileTypeIcon';
import PublishToggle from '@/components/PublishToggle';

export default {
  name: 'PrivateDocumentsList',
  components: { FileTypeIcon, PublishToggle },
  props: {
    documents: {
      type: Array,
    },
    canChangeOrders: {
      type: Boolean,
    },
    selected: {
      type: Array,
    },
  },
  computed: {
    disableSelectAll() {
      return this.documents.every((d) => d.isDeleted);
    },
  },
  watch: {
    selected() {
      this.checkSelected();
    },
  },
  data() {
    return {
      headers:
        this.$route.name == 'CompanyDocumentLibrary'
          ? [
              {
                text: 'Name',
                value: 'name',
                class: 'lightBg',
              },
              {
                text: 'Created Date',
                value: 'createdAt',
                width: '130px',
                class: 'lightBg',
              },
              {
                text: 'Expiry Date',
                value: 'expiryDate',
                width: '130px',
                class: 'lightBg',
              },
              {
                text: 'Actions',
                value: 'actions',
                align: 'center',
                sortable: false,
                width: '212px',
                class: 'lightBg',
              },
            ]
          : [
              {
                text: 'Name',
                value: 'name',
                class: 'lightBg',
              },
              {
                text: 'Created Date',
                value: 'createdAt',
                width: '130px',
                class: 'lightBg',
              },
              {
                text: 'Expiry Date',
                value: 'expiryDate',
                width: '130px',
                class: 'lightBg',
              },
            ],
      thisSelected: [],
    };
  },
  methods: {
    checkSelected() {
      if (this.selected.length == 0) {
        this.thisSelected = [];
      } else {
        this.thisSelected = this.documents.filter((document) =>
          this.selected.some((selected) => selected.id == document.id),
        );
      }
    },
    onSelect(event) {
      if (event.item != undefined) {
        this.onSelectOne(event);
      }

      // Handle event when user uses SHIFT to select multiple items
      if (event.currentItem != undefined) {
        event.item = event.currentItem;
        this.onSelectOne(event);
      }

      if (event.items != undefined) {
        this.onSelectAll(event);
      }
    },
    onSelectOne(event) {
      this.$emit('selected', {
        docs: [event.item],
        value: event.value,
      });
    },
    onSelectAll(event) {
      const selectables = event.items.filter((i) => i.isSelectable);
      if (JSON.stringify(selectables) == JSON.stringify(this.thisSelected)) {
        event.value = false;
      }
      if (event.value) {
        this.thisSelected = event.items.filter((i) => i.isSelectable);
      } else {
        this.$nextTick(() => {
          this.thisSelected = [];
        });
      }

      this.$emit('selected', {
        docs: this.thisSelected,
        value: event.value,
      });
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        loaderText: 'Loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-private-library-document?format=json', params, options)
        .then((response) => {
          if (this.$route.name == 'CompanyDocumentLibrary') {
            this.$helpers.downloadFile(response.data, doc.originalFilename);
          }
          if (this.$route.name == 'UserDocumentLibrary') {
            this.$helpers.previewFileInNewWindow(response.data);
          }
        })
        .catch((error) => error);
    },
    togglePublish(document) {
      const confirm = () => {
        const params = {
          loaderText: 'Saving...',
          id: document.id,
        };
        const url = document.isPublished
          ? 'unpublish-private-library-document?format=json'
          : 'publish-private-library-document?format=json';

        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('reloadList');
          })
          .catch((error) => error);
      };

      if (document.isPublished) {
        if (document.hasLabelOrSchedule || document.assignmentCount > 0) {
          this.$root.showMessage(
            'Warning',
            'This document is currently assigned to a user, attached to a schedule or a group. <br>If you unpublish the document, it will unassign/remove the document from the user, schedule or group.',
            () => confirm(),
            () => false,
            'Confirm',
            'Cancel',
          );
          return;
        }
      } else {
        if (
          this.$helpers.splitFilenameByExtension(document.originalFilename)
            .extension != 'pdf'
        ) {
          this.$root.showMessage(
            'Confirm',
            'This document will be converted to PDF after publish.',
            () => confirm(),
            () => false,
            'Confirm',
            'Cancel',
          );
          return;
        }
      }

      confirm();
    },
    toggleDeletion(document) {
      const confirm = () => {
        const params = {
          loaderText: 'Loading...',
        };

        let url = '';
        if (document.isDeleted) {
          params.id = document.id;
          url = 'restore-private-library-document?format=json';
        } else {
          params.docIds = [document.id];
          url = 'delete-private-library-documents?format=json';
        }

        this.$axios
          .post(url, params)
          .then(() => {
            this.onSelectOne({ item: document, value: false });
            this.$emit('reloadList');
          })
          .catch((error) => error);
      };

      this.$root.showMessage(
        'Confirm',
        document.isDeleted
          ? 'Are you sure you want to restore this document?'
          : 'Are you sure you want to delete this document?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
  },
  mounted() {
    this.checkSelected();
  },
};
</script>

<style>
.private-document-list .v-data-table-header th {
  background-color: #ebf1fc !important;
  border-bottom: none !important;
}
</style>
