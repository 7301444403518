<template>
  <v-dialog v-model="dialog" width="1200px" persistent style="height: 70vh">
    <v-card>
      <v-card-title class="msaBlue white--text"> File Details </v-card-title>
      <v-card-subtitle class="msaBlue white--text">
        Last updated By {{ edited.updatedByName }} on
        {{ edited.updatedAt | filterAsLocalDate }}
      </v-card-subtitle>
      <v-card-text :class="`pt-4 ${isMobile ? 'px-2' : ''}`">
        <v-row>
          <v-col>
            <v-form v-model="isValid">
              <v-text-field
                v-model="edited.name"
                dense
                outlined
                label="Name"
                counter
                maxlength="1000"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row dense v-if="!isMobile">
          <v-col>
            <v-text-field
              v-model="edited.originalFilename"
              outlined
              dense
              readonly
              label="File"
            >
            </v-text-field>
          </v-col>
          <v-col class="shrink" :key="reloadUploader">
            <v-btn class="msaBlue white--text" @click="$refs.uploader.click()">
              Replace File
            </v-btn>
            <input
              type="file"
              ref="uploader"
              class="d-none"
              accept=".pdf, .doc, .docx, .xls, .xlsx"
              @change="replaceDocument($event)"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="isMobile">
          <v-col cols="12">
            <v-text-field
              v-model="edited.originalFilename"
              outlined
              dense
              readonly
              hide-details
              label="File"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              class="msaBlue white--text"
              @click="$refs.uploader.click()"
              width="100%"
            >
              Replace File
            </v-btn>
            <input
              type="file"
              ref="uploader"
              class="d-none"
              accept=".pdf, .doc, .docx, .xls, .xlsx"
              @change="replaceDocument($event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :class="isMobile ? 'pt-4' : ''">
            <CustomizedAutoComplete
              :value="edited.privateLibraryGroupId"
              label="Folder"
              hide-details
              :items="groups"
              @change="edited.privateLibraryGroupId = $event"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="expiryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :key="reloadDatePicker"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edited.expiryDate"
                  label="Expiration date"
                  readonly
                  dense
                  outlined
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="edited.expiryDate"
                :min="minDate"
                @input="expiryDateMenu = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <PublishButtonGroup
              :is-published="edited.isPublished"
              @click="edited.isPublished = $event"
            />
          </v-col>
          <v-col v-if="showPublishWarning">
            <v-alert dense outlined type="warning">
              This file will be converted to PDF after publish.
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="msaBlue--text text-h6">
            <v-icon color="msaBlue">mdi-history</v-icon>
            History
          </v-col>
          <v-col>
            <DocumentHistory :histories="document.histories" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="font-weight-bold"
          @click="dialog = false"
          :class="(disableSave ? 'msaBlue' : 'red') + '--text'"
        >
          {{ disableSave ? 'Close' : 'Cancel' }}
        </v-btn>

        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="saveDetails()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      light
      color="green accent-3"
    >
      File replaced.
      <template v-slot:action="{ attrs }">
        <v-btn icon small v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import DocumentHistory from '@/components/DocumentHistory.vue';
import PublishButtonGroup from '@/components/PublishButtonGroup';
export default {
  name: 'PrivateDocumentDetails',
  props: {
    documentId: {
      type: Number,
    },
    groups: {
      type: Array,
    },
  },
  components: {
    DocumentHistory,
    PublishButtonGroup,
  },
  data() {
    return {
      dialog: false,
      document: {},
      edited: {},
      expiryDateMenu: false,
      history: [],
      isValid: true,
      minDate: this.$constants.DATE_SELECTOR_RANGE.MIN,
      reloadAlertFileSizeKey: 3333,
      reloadDatePicker: -9999,
      reloadHistory: false,
      reloadUploader: 9999,
      snackbar: false,
      timeout: 2500,
      today: this.$helpers.getToday(),
    };
  },
  watch: {
    dialog(onOpen) {
      if (!onOpen) {
        this.reloadDatePicker--;
        this.expiryDateMenu = false; // Date picker may stay open if you don't force it to close
      }
    },
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.edited) == JSON.stringify(this.document) ||
        !this.isValid
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showPublishWarning() {
      return (
        this.document.isPublished == 0 &&
        this.edited.isPublished == 1 &&
        this.$helpers.splitFilenameByExtension(this.document.originalFilename)
          .extension != 'pdf'
      );
    },
  },
  methods: {
    getDetail() {
      const params = {
        loaderText: 'Loading...',
        id: this.documentId,
      };
      const url = 'get-private-library-document-detail?format=json';

      return this.$axios.post(url, params).then((response) => {
        this.document = response.data;
        this.document.expiryDate = this.$options.filters.filterAsLocalDate(
          this.document.expiryDate,
        );

        this.edited = JSON.parse(JSON.stringify(this.document));
      });
    },
    async replaceDocument(e) {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      try {
        await this.$helpers.processFile(
          file,
          this.$constants.ACCEPTED_MIME_TYPES.PDF.concat(
            this.$constants.ACCEPTED_MIME_TYPES.WORD,
            this.$constants.ACCEPTED_MIME_TYPES.EXCEL,
          ),
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }

      const edited = JSON.parse(JSON.stringify(this.edited));
      const url = 'replace-private-library-document';
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();
      params.append('file', file);
      params.append('type', file.type);
      params.append('originalFilename', file.name);
      params.append('documentId', this.documentId);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', 'Uploading...');

      this.$axios.post(url, params, headers).then(() => {
        this.getDetail().then(() => {
          this.edited.name = edited.name;
          this.edited.expiryDate = edited.expiryDate;
          this.edited.isPublished = edited.isPublished;
        });
        this.reloadUploader++;
        this.$emit('reloadList');
      });
    },
    saveDetails() {
      const params = {
        loaderText: 'Saving...',
        documentId: this.documentId,
      };

      if (this.document.name != this.edited.name.trim()) {
        params.name = this.edited.name.trim();
      }

      if (
        this.document.privateLibraryGroupId != this.edited.privateLibraryGroupId
      ) {
        params.groupId = this.edited.privateLibraryGroupId;
      }

      if (this.document.expiryDate != this.edited.expiryDate) {
        params.expiryDate =
          this.edited.expiryDate == null || this.edited.expiryDate == ''
            ? ''
            : this.$helpers.formatDateTimeToUTC(this.edited.expiryDate);
      }

      if (this.document.isPublished != this.edited.isPublished) {
        params.isPublished = this.edited.isPublished;
      }

      const url = 'save-private-library-document-detail?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.dialog = false;
          this.$emit('reloadList');
        })
        .catch((error) => error);
    },
    show() {
      this.$nextTick(() => {
        this.getDetail()
          .then(() => {
            this.dialog = true;
          })
          .catch((error) => error);
      });
    },
  },
};
</script>
